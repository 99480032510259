import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Achetez Neosurf en ligne avec des cryptos"
      description="Pour les utilisateurs habitués à Neosurf, Bitcoin offre un niveau similaire de confidentialité et de contrôle sur vos dépenses."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/buy-neosurf-online',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/acheter-neosurf-en-ligne',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/kaufen-neosurf-online',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Acheter Neosurf en ligne</h1>
            <p className="textColorDark">Pas de Neosurf dans votre région ? Découvrez une alternative crypto !</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Les bons Neosurf sont des moyens de paiement fiables et largement utilisés pour des transactions en ligne sûres et anonymes. Malheureusement, les bons Neosurf ne peuvent pas être achetés directement en ligne sur GetNeosurf.com pour le moment. Mais ne vous inquiétez pas, il existe une solution alternative pour vos besoins de paiement en ligne – Bitcoin.</p>
      <p className="textColorDark">Bitcoin est la principale cryptomonnaie au monde et offre les mêmes avantages en termes de sécurité, d’anonymat et d’acceptation mondiale. Avec Bitcoin, vous pouvez profiter d’une expérience de paiement numérique sans dépendre des méthodes de paiement traditionnelles. La crypto peut être utilisée pour des achats en ligne, des jeux ou pour alimenter des comptes sur diverses plateformes comme LuckyStar.io. Bitcoin est une alternative polyvalente et tournée vers l’avenir.</p>
      <p className="textColorDark">Passer à Bitcoin est plus simple que vous ne le pensez. Voici comment commencer :</p>
      <ol>
        <li>
          <p className="textColorDark">Configurez un portefeuille Bitcoin - C’est là où votre Bitcoin sera stocké. Vous avez plusieurs options, comme des applications mobiles, des logiciels de bureau ou même des portefeuilles physiques pour plus de sécurité.</p>
        </li>
        <li>
          <p className="textColorDark">Achetez du Bitcoin - Vous pouvez acheter du Bitcoin sur des plateformes de cryptomonnaies fiables ou via des plateformes en ligne en utilisant des méthodes de paiement telles que virement bancaire, carte de crédit ou autres portefeuilles électroniques.</p>
        </li>
        <li>
          <p className="textColorDark">Effectuez votre paiement - Une fois le Bitcoin dans votre portefeuille, vous pouvez l’utiliser pour effectuer des paiements sur des plateformes qui acceptent la cryptomonnaie, comme LuckyStar.io.</p>
        </li>
      </ol>
      <p className="textColorDark">L’un des principaux avantages de Bitcoin est qu’il peut être utilisé presque partout grâce à son accessibilité mondiale. Contrairement aux bons traditionnels, qui peuvent être limités dans certaines régions, Bitcoin peut être acheté et utilisé depuis n’importe où dans le monde. Les transactions Bitcoin sont également incroyablement rapides et comportent des frais inférieurs par rapport à d’autres méthodes de paiement.</p>
      <p className="textColorDark">Pour les utilisateurs habitués à Neosurf, Bitcoin offre un niveau similaire de confidentialité et de contrôle sur vos dépenses. Tout comme les bons Neosurf, Bitcoin ne nécessite pas de partager des informations financières sensibles lors des transactions. Cela en fait une excellente option pour ceux qui accordent la priorité à l’anonymat et à la sécurité.</p>
      <p className="textColorDark">Sur LuckyStar.io, vous pouvez utiliser Bitcoin pour déposer des fonds de manière instantanée et sécurisée. C’est une excellente alternative pour les utilisateurs à la recherche d’un moyen fiable et flexible de financer leurs comptes. Avec Bitcoin, vous n’êtes pas limité par la disponibilité des bons, ce qui fait de Bitcoin une solution toujours accessible et prête à l’emploi.</p>
      <p className="textColorDark">Bien que les bons Neosurf restent une méthode de paiement fantastique, explorer Bitcoin comme alternative jusqu’à ce que les bons soient disponibles dans votre région pourrait ouvrir de nouvelles possibilités pour les transactions en ligne. Prêt à franchir le pas ? Rendez-vous sur LuckyStar.io pour en savoir plus sur l’utilisation de Bitcoin pour des paiements sécurisés !</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
